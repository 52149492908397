import { Button, ButtonGroup } from "@blueprintjs/core";
import { Battery } from "@ec1/types/Battery";
import { DateTime } from "luxon";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useFetch } from "src/common/fetcher/effects";
import {
  IFilter,
  IFilterDefinition,
} from "src/common/table/PlatformPowerSearchBar.react";
import PlatformTable, {
  TableOrdering,
} from "src/common/table/PlatformTable.react";
import Ec1MultiSelect, {
  Ec1MultiSelectItem,
} from "src/ui/components/Ec1MultiSelect.react";
import { EnergyUtils } from "src/ui/utils/chartUtils";
import {
  buildUrlParams,
  calculateMaxPage,
  createOrderingUpdater,
  parseURL,
  updateURL,
} from "src/ui/utils/gridStateUtils";
import PlatformListView from "../../../common/layout/PlatformListView.react";
import PlatformSiteCustomerClickableLabel from "../customers/PlatformSiteCustomerClickableLabel.react";
import { useProperties } from "../properties/properties";
import BatteryBrandNameTag from "./BatteryBrandNameTag.react";
import BatteryChargingModeTag from "./BatteryChargingModeTag.react";
import BatteryGridStatusTag from "./BatteryGridStatusTag.react";
import BatteryStationStateTag from "./BatteryStationStateTag.react";
import BatteryStatusTag from "./BatteryStatusTag.react";
import EllipsisNowrapTextDiv from "./EllipsisNowrapTextdivProps.react";

const DEFAULT_PAGE_SIZE = 25;

const STATUSES: Ec1MultiSelectItem[] = [
  {
    id: "online",
    label: "Online",
    component: (
      <BatteryStationStateTag
        battery={{ station_state: "ONLINE" } as Battery}
      />
    ),
  },
  {
    id: "offline",
    label: "Offline",
    component: (
      <BatteryStationStateTag
        battery={{ station_state: "OFFLINE" } as Battery}
      />
    ),
  },
  {
    id: "fault",
    label: "Fault",
    component: (
      <BatteryStationStateTag battery={{ station_state: "FAULT" } as Battery} />
    ),
  },
];

const CHARGING_MODES: Ec1MultiSelectItem[] = [
  {
    id: "smart",
    label: "Smart Charging",
    component: (
      <BatteryChargingModeTag
        battery={{ is_smart_charging: true } as Battery}
      />
    ),
  },
  {
    id: "manual",
    label: "Manual",
    component: (
      <BatteryChargingModeTag
        battery={{ is_smart_charging: false } as Battery}
      />
    ),
  },
];

export default function PlatformDeviceListView() {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [ordering, setOrdering] = useState<TableOrdering[]>([]);
  const [filters, setFilters] = useState<IFilter[]>([]);
  const navigate = useNavigate();
  const location = useLocation();
  const isUrlUpdate = useRef(false);

  const [selectedStatuses, setSelectedStatuses] = useState<
    Ec1MultiSelectItem[]
  >([]);
  const [selectedChargingModes, setSelectedChargingModes] = useState<
    Ec1MultiSelectItem[]
  >([]);

  const { properties } = useProperties();

  const columns = useMemo(
    () =>
      [
        {
          id: "BRAND",
          header: "Brand",
          sortable: true,
          render: (item: Battery) => <BatteryBrandNameTag battery={item} />,
        },
        {
          id: "STATION_NAME",
          header: "Station name",
          sortable: true,
          render: (item: Battery) => (
            <EllipsisNowrapTextDiv>
              {String(item.station_name ?? "-")}
            </EllipsisNowrapTextDiv>
          ),
        },
        {
          id: "STATION_STATE",
          header: "Status",
          sortable: true,
          render: (item: Battery) => <BatteryStationStateTag battery={item} />,
        },
        {
          id: "BATTERY_STATE",
          header: "Battery State",
          sortable: true,
          render: (item: Battery) => <BatteryStatusTag battery={item} />,
        },
        {
          id: "CHARGING_MODE",
          header: "Charging Mode",
          sortable: true,
          render: (item: Battery) => <BatteryChargingModeTag battery={item} />,
        },
        {
          id: "BATTERY_LEVEL",
          header: "Battery level",
          sortable: true,
          render: (item: Battery) => (
            <div>
              {item?.battery_level || item?.battery_level === 0
                ? `${item?.battery_level}%`
                : "-"}
            </div>
          ),
        },
        {
          id: "SOLAR_POWER",
          header: "Solar Power",
          sortable: true,
          render: (item: Battery) => (
            <EllipsisNowrapTextDiv>
              {String(EnergyUtils.humanize(item.solar_power ?? 0, "w"))}
            </EllipsisNowrapTextDiv>
          ),
        },
        {
          id: "GRID_STATE",
          header: "Grid Power",
          sortable: true,
          render: (item: Battery) => <BatteryGridStatusTag battery={item} />,
        },
        {
          id: "SYNCED_AT",
          header: "Last sync",
          sortable: true,
          render: (item: Battery) => (
            <EllipsisNowrapTextDiv>
              {item.synced_at
                ? String(DateTime.fromISO(item.synced_at).toRelative())
                : "-"}
            </EllipsisNowrapTextDiv>
          ),
        },
        {
          id: "CUSTOMER",
          header: "Customer",
          sortable: false,
          render: (item: Battery) => {
            const property = properties.find(
              (p) => p.id === item.assigned_property
            );
            return (
              <div style={{ marginTop: -5 }}>
                <PlatformSiteCustomerClickableLabel
                  customerId={property?.customer?.toString() ?? ""}
                />
              </div>
            );
          },
        },
      ].map((c) => ({ ...c, href: (item: Battery) => `/devices/${item.id}` })),
    [properties]
  );

  const filterDefs = useMemo<IFilterDefinition[]>(
    () => [
      {
        id: "d_status",
        name: "Status",
        operators: ["in"],
      },
      {
        id: "d_charging_mode",
        name: "Charging Mode",
        operators: ["in"],
      },
    ],
    []
  );

  const fieldMap = useMemo(
    () => ({
      battery_power: "BATTERY_STATE",
      is_smart_charging: "CHARGING_MODE",
    }),
    []
  );

  const parsedURL = useMemo(
    () =>
      parseURL(
        location.search,
        isUrlUpdate,
        navigate,
        filterDefs,
        DEFAULT_PAGE_SIZE,
        fieldMap
      ),
    [location.search, navigate, filterDefs, fieldMap]
  );

  useEffect(() => {
    const {
      page: newPage,
      pageSize: newPageSize,
      ordering: newOrdering,
      filters: newFilters,
    } = parsedURL;
    isUrlUpdate.current = true;
    setPage(newPage);
    setPageSize(newPageSize);
    setOrdering(newOrdering);
    setFilters(newFilters);

    // Set the status filter dropdown
    const statusFilter = newFilters.filter(
      (f) => f.filterDefinitionId === "d_status"
    );
    if (statusFilter?.length) {
      const values = statusFilter[0].value?.split(",");
      const statuses: Ec1MultiSelectItem[] = [];
      if (values?.length) {
        values.forEach((v) => {
          const items = STATUSES.filter((s) => s.id === v);
          if (items?.length) {
            statuses.push(items[0]);
          }
        });
        setSelectedStatuses(statuses);
      }
    }

    // Set the charging mode filter dropdown
    const chargingModeFilter = newFilters.filter(
      (f) => f.filterDefinitionId === "d_charging_mode"
    );
    if (chargingModeFilter?.length) {
      const values = chargingModeFilter[0].value?.split(",");
      const chargingModes: Ec1MultiSelectItem[] = [];
      if (values?.length) {
        values.forEach((v) => {
          const items = CHARGING_MODES.filter((m) => m.id === v);
          if (items?.length) {
            chargingModes.push(items[0]);
          }
        });
        setSelectedChargingModes(chargingModes);
      }
    }
  }, [parsedURL]);

  const [currentUrl, endpointUrl] = useMemo((): [string, string] => {
    const paramString = buildUrlParams(
      page,
      pageSize,
      ordering,
      filters,
      filterDefs,
      fieldMap
    );

    // Process dropdown params
    const queryParams = paramString.split("&");
    const params: string[] = [];

    queryParams.forEach((param) => {
      const [key, value] = param.split("=");
      if (key.startsWith("d_charging_mode")) {
        switch (value) {
          case "manual,smart":
          case "smart,manual":
            params.push("is_smart_charging__in=True,False");
            break;
          case "manual":
            params.push("is_smart_charging=False");
            break;
          case "smart":
            params.push("is_smart_charging=True");
            break;
          default:
            break;
        }
      } else if (key.startsWith("d_status")) {
        params.push("station_state__in=" + value.toUpperCase());
      } else {
        params.push(param);
      }
    });

    return [
      `/devices/?${paramString}`,
      `/api/devices/batteries/?${params.join("&")}`,
    ];
  }, [ordering, page, pageSize, filters, filterDefs, fieldMap]);

  useEffect(() => {
    updateURL(currentUrl, navigate, isUrlUpdate);
  }, [currentUrl, navigate]);

  const { data: deviceData, isLoading: isLoadingBatteries } = useFetch<{
    results: Battery[];
    count: number;
  }>(endpointUrl);

  useEffect(() => {
    if (!isLoadingBatteries && deviceData) {
      const maxPage = calculateMaxPage(deviceData.count, pageSize);
      if (page > maxPage) {
        isUrlUpdate.current = false;
        setPage(1);
      }
    }
  }, [isLoadingBatteries, deviceData, page, pageSize]);

  const handlePageChange = useCallback((newPage: number) => {
    isUrlUpdate.current = false;
    setPage(newPage);
  }, []);

  const handlePageSizeChange = useCallback((newPageSize: number) => {
    isUrlUpdate.current = false;
    setPageSize(newPageSize);
    setPage(1); // Reset to first page when changing page size
  }, []);

  const handleOrderingChange = useCallback((newOrdering: TableOrdering[]) => {
    isUrlUpdate.current = false;
    setOrdering(createOrderingUpdater(newOrdering));
    setPage(1);
  }, []);

  /* Uncomment to use filtering */
  // const handleFilterChange = useCallback((newFilters: any[]) => {
  //   isUrlUpdate.current = false;
  //   setFilters(newFilters);
  // }, []);

  // Handler for status selection
  const handleStatusSelect = useCallback(
    (selectedItems: Ec1MultiSelectItem[]) => {
      setSelectedStatuses(selectedItems);

      isUrlUpdate.current = false;
      setFilters((prevFilters: IFilter[]) => {
        // Remove existing d_status filter
        const otherFilters = prevFilters.filter(
          (filter) => filter.filterDefinitionId !== "d_status"
        );

        if (selectedItems.length > 0) {
          const statusValues = selectedItems.map((item) => item.id);
          return [
            ...otherFilters,
            {
              filterDefinitionId: "d_status",
              operator: "in",
              value: statusValues.join(","),
            },
          ];
        } else {
          // No selection, don't add the filter
          return otherFilters;
        }
      });
    },
    []
  );

  // Handler for charging mode selection
  const handleChargingModeSelect = useCallback(
    (selectedItems: Ec1MultiSelectItem[]) => {
      setSelectedChargingModes(selectedItems);

      isUrlUpdate.current = false;
      setFilters((prevFilters: IFilter[]) => {
        // Remove existing d_charging_mode filter
        const otherFilters = prevFilters.filter(
          (filter) => filter.filterDefinitionId !== "d_charging_mode"
        );

        if (selectedItems.length > 0) {
          const chargingModeValues = selectedItems.map((item) => item.id);
          return [
            ...otherFilters,
            {
              filterDefinitionId: "d_charging_mode",
              operator: "in",
              value: chargingModeValues.join(","),
            },
          ];
        } else {
          // No selection, don't add the filter
          return otherFilters;
        }
      });
    },
    []
  );
  return (
    <PlatformListView title="Devices">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          minHeight: 0,
          height: "100%",
          boxSizing: "border-box",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            fontFamily: "Barlow",
            letterSpacing: 1.5,
            fontWeight: 700,
            fontSize: 24,
            color: "#D3D8DE",
            marginLeft: "4px",
          }}
        >
          <div
            style={{ marginTop: "12px", display: "flex", flexDirection: "row" }}
          >
            <Button
              minimal
              style={{ marginRight: "12px" }}
              outlined
              icon="people"
              onClick={() => {
                navigate("/vendor/account");
              }}
            >
              Vendor accounts
            </Button>
            <div style={{ flex: 1, justifyItems: "flex-end" }}>
              <ButtonGroup
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "12px",
                }}
              >
                <Ec1MultiSelect
                  items={STATUSES}
                  selectedItems={selectedStatuses}
                  title="Status"
                  titlePlural="Statuses"
                  onSelect={handleStatusSelect}
                  // disabled={isLoadingBatteries} // Uncomment for loading flow
                />
                <Ec1MultiSelect
                  items={CHARGING_MODES}
                  selectedItems={selectedChargingModes}
                  title="Charging Mode"
                  titlePlural="Charging Modes"
                  onSelect={handleChargingModeSelect}
                  // disabled={isLoadingBatteries} // Uncomment for loading flow
                />
              </ButtonGroup>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            paddingBottom: "12px",
            minHeight: 0,
          }}
        >
          <PlatformTable<Battery>
            interactive
            glimmer={isLoadingBatteries}
            data={deviceData?.results ?? []}
            columns={columns}
            pageSize={pageSize}
            onPageSizeChange={handlePageSizeChange}
            numberOfAllItems={deviceData?.count ?? 5}
            handlePageChange={handlePageChange}
            currentPage={page}
            ordering={ordering}
            onOrderingChange={handleOrderingChange}
          />
        </div>
      </div>
    </PlatformListView>
  );
}
