import { H5, Spinner } from "@blueprintjs/core";
import HighchartsReact from "highcharts-react-official";
import Highcharts, {
  TooltipFormatterContextObject,
} from "highcharts/highstock";
import { DateTime } from "luxon";
import { useMemo, useState } from "react";
import useBatteryChargingPlan, {
  ChartBatteryChargingPlanPoint,
  ChartBatteryChargingPlanSocPoint,
} from "./useBatteryChargingPlan";

interface PlatformBatteryChargingScheduleChartProps {
  batteryId: number;
  propertyId?: number;
  height?: number;
}

export default function PlatformBatteryChargingScheduleChart({
  batteryId,
  propertyId,
  height = 240,
}: PlatformBatteryChargingScheduleChartProps) {
  const {
    batteryChargingPlan,
    isFetchingBatteryChargingPlan,
    timezone,
    timezoneLabel,
    currency,
  } = useBatteryChargingPlan(batteryId, propertyId);

  const [visibleSeries, setVisibleSeries] = useState({
    gridCharging: true,
    solarWindow: true,
    exporting: true,
    idle: true,
    batteryLevel: true,
    importPrice: true,
    exportPrice: true,
  });

  const hasData = useMemo(() => {
    return (
      batteryChargingPlan &&
      batteryChargingPlan.socData &&
      batteryChargingPlan.socData.length > 0
    );
  }, [batteryChargingPlan]);

  const chartOptions = useMemo(() => {
    const localNow = DateTime.now().setZone(timezone || "system");

    return {
      chart: {
        height: height - 20,
        marginLeft: 58,
        marginRight: 45,
        marginTop: 20,
        marginBottom: 108,
        zoomType: false,
      },
      title: {
        text: null,
      },
      time: {
        timezone,
        useUTC: timezone !== undefined,
      },
      legend: {
        enabled: true,
        align: "center",
        verticalAlign: "bottom",
        width: 550,
        x: 20,
        y: 8,
        padding: 0,
        symbolWidth: 14,
        itemStyle: {
          color: "white",
          fontFamily: "Barlow",
        },
        itemHoverStyle: {
          color: "#ccc",
          fontFamily: "Barlow",
        },
      },
      xAxis: {
        lineWidth: 0,
        tickLength: 5,
        crosshair: true,
        title: {
          x: 3,
          text: `Time<br><span style="font-size: 11px; color: #999;">${timezoneLabel}</span>`,
        },
        plotBands: [
          ...(visibleSeries.solarWindow
            ? [
                {
                  from: batteryChargingPlan?.solarPeriod?.start,
                  to: batteryChargingPlan?.solarPeriod?.end,
                  color: "rgba(255, 87, 51, 0.25)",
                },
              ]
            : []),
          ...(visibleSeries.gridCharging
            ? batteryChargingPlan?.chargeSlots.map((slot) => ({
                from: slot.start,
                to: slot.end,
                color:
                  slot.start <= localNow.toMillis()
                    ? "rgba(62, 176, 233, 0.35)"
                    : "rgba(62, 176, 233, 0.25)",
              })) || []
            : []),
          ...(visibleSeries.exporting
            ? batteryChargingPlan?.exportSlots.map((slot) => ({
                from: slot.start,
                to: slot.end,
                color:
                  slot.start <= localNow.toMillis()
                    ? "rgba(25, 189, 60, 0.35)"
                    : "rgba(25, 189, 60, 0.25)",
              })) || []
            : []),
          ...(visibleSeries.idle
            ? batteryChargingPlan?.idleSlots.map((slot) => ({
                from: slot.start,
                to: slot.end,
                color:
                  slot.start <= localNow.toMillis()
                    ? "rgba(150, 150, 150, 0.35)"
                    : "rgba(150, 150, 150, 0.25)",
              })) || []
            : []),
        ],
      },
      yAxis: [
        {
          opposite: false,
          showLastLabel: true,
          title: {
            text: null,
          },
          labels: {
            y: 4,
            style: {
              fontFamily: "Barlow",
              color: "white",
            },
            formatter: function (
              this: Highcharts.AxisLabelsFormatterContextObject
            ): string {
              const value =
                typeof this.value === "number"
                  ? this.value.toFixed(2)
                  : this.value;
              return (
                (typeof this.value === "number" && parseFloat(value) < 0
                  ? "-"
                  : "") +
                (currency ?? "") +
                value
              );
            },
          },
          gridLineWidth: 0,
        },
        {
          min: 0,
          max: 105,
          opposite: true,
          endOnTick: false,
          tickInterval: 50,
          showLastLabel: true,
          title: {
            text: null,
          },
          labels: {
            y: 4,
            style: {
              fontFamily: "Barlow",
              color: "white",
            },
            formatter: function (
              this: Highcharts.AxisLabelsFormatterContextObject
            ): string {
              return this.value + "%";
            },
          },
          gridLineWidth: 0,
        },
      ],
      tooltip: {
        useHTML: true,
        shared: true,
        formatter: function (this: TooltipFormatterContextObject): string {
          const points = this.points;

          if (
            !points ||
            points.length === 0 ||
            typeof points[0].x !== "number"
          ) {
            return "";
          }

          const pointDate = DateTime.fromMillis(points[0].x).setZone(
            timezone || "system"
          );

          const dateStr = pointDate.toFormat(
            `cccc, dd LLLL ${pointDate.toFormat("HH:mm")}`
          );

          let tooltipContent = `<div style="font-size: 12px; margin-bottom: 5px;">${dateStr}</div>`;

          points.forEach((point) => {
            if (point.series.visible && typeof point.y === "number") {
              const seriesName = point.series.name;
              const bulletPoint = `<span style="color:${point.color};">\u25CF</span>`;

              if (
                point.series.options.id &&
                (parseInt(point.series.options.id) === 1 ||
                  parseInt(point.series.options.id) === 2)
              ) {
                const sign = point.y < 0 ? "-" : "";
                const valueStr = `${sign}${currency}${point.y.toFixed(2)}`;
                tooltipContent += `<div>${bulletPoint} ${seriesName}: <b>${valueStr}</b></div>`;
              } else if (
                point.series.options.id &&
                parseInt(point.series.options.id) === 3
              ) {
                const valueStr = `${point.y.toFixed(1)}%`;
                tooltipContent += `<div>${bulletPoint} ${seriesName}: <b>${valueStr}</b></div>`;
              }
            }
          });

          return tooltipContent;
        },
        style: {
          fontFamily: "Barlow, sans-serif",
        },
      },
      series: [
        {
          id: 1,
          yAxis: 0,
          type: "line",
          name: "Import Price/kWh",
          color: "#DB2C6F",
          zIndex: 3,
          step: true,
          visible: visibleSeries.importPrice,
          showInLegend: false,
          data: batteryChargingPlan?.importPrices.map(
            (p: ChartBatteryChargingPlanPoint) => [p.timestamp, p.value]
          ),
          states: {
            hover: {
              halo: null,
            },
          },
          marker: {
            enabled: false,
            radius: 1,
            symbol: "circle",
          },
        },
        {
          id: 2,
          yAxis: 0,
          type: "line",
          name: "Export Price/kWh",
          color: "#5aebf7",
          zIndex: 2,
          step: true,
          visible: visibleSeries.exportPrice,
          showInLegend: false,
          data: batteryChargingPlan?.exportPrices.map(
            (p: ChartBatteryChargingPlanPoint) => [p.timestamp, p.value]
          ),
          states: {
            hover: {
              halo: null,
            },
          },
          marker: {
            enabled: false,
            radius: 1,
            symbol: "circle",
          },
        },
        {
          id: 3,
          yAxis: 1,
          type: "column",
          borderWidth: 0,
          name: "SoC",
          zIndex: 1,
          showInLegend: false,
          visible: visibleSeries.batteryLevel,
          color: "rgb(255, 210, 102)",
          data: batteryChargingPlan?.socData.map(
            (p: ChartBatteryChargingPlanSocPoint) => ({
              x: p.timestamp,
              y: p.value,
              opacity:
                p.timestamp > batteryChargingPlan?.lastSocTimestamp
                  ? 0.3
                  : 0.75,
              color:
                p.timestamp > batteryChargingPlan?.lastSocTimestamp
                  ? "rgb(255, 210, 102)"
                  : p.state === "GRID CHARGING"
                  ? "rgb(62, 176, 233)"
                  : p.state === "EXPORTING"
                  ? "rgb(25, 189, 60)"
                  : p.state === "SOLAR CHARGING"
                  ? "rgb(255, 87, 51)"
                  : "rgb(255, 210, 102)",
            })
          ),
        },
        // Legend items
        {
          name: "Grid Charging",
          color: "#3eb0e9",
          type: "spline",
          visible: visibleSeries.gridCharging,
          events: {
            legendItemClick: function () {
              setVisibleSeries((prev) => ({
                ...prev,
                gridCharging: !prev.gridCharging,
              }));
              return false;
            },
          },
        },
        {
          name: "Solar Window",
          color: "#b7410e",
          type: "spline",
          visible: visibleSeries.solarWindow,
          events: {
            legendItemClick: function () {
              setVisibleSeries((prev) => ({
                ...prev,
                solarWindow: !prev.solarWindow,
              }));
              return false;
            },
          },
        },
        {
          name: "Battery Exporting",
          color: "rgb(25, 189, 60)",
          type: "spline",
          visible: visibleSeries.exporting,
          events: {
            legendItemClick: function () {
              setVisibleSeries((prev) => ({
                ...prev,
                exporting: !prev.exporting,
              }));
              return false;
            },
          },
        },
        {
          name: "Battery Level",
          color: "rgb(255, 210, 102)",
          type: "spline",
          visible: visibleSeries.batteryLevel,
          events: {
            legendItemClick: function () {
              setVisibleSeries((prev) => ({
                ...prev,
                batteryLevel: !prev.batteryLevel,
              }));
              return false;
            },
          },
        },
        {
          name: "Idle",
          color: "rgb(150, 150, 150)",
          type: "spline",
          visible: visibleSeries.idle,
          events: {
            legendItemClick: function () {
              setVisibleSeries((prev) => ({
                ...prev,
                idle: !prev.idle,
              }));
              return false;
            },
          },
        },
        {
          name: "Import Price",
          color: "#DB2C6F",
          type: "spline",
          visible: visibleSeries.importPrice,
          events: {
            legendItemClick: function () {
              setVisibleSeries((prev) => ({
                ...prev,
                importPrice: !prev.importPrice,
              }));
              return false;
            },
          },
        },
        {
          name: "Export Price",
          color: "#5aebf7",
          type: "spline",
          visible: visibleSeries.exportPrice,
          events: {
            legendItemClick: function () {
              setVisibleSeries((prev) => ({
                ...prev,
                exportPrice: !prev.exportPrice,
              }));
              return false;
            },
          },
        },
      ],
      rangeSelector: {
        enabled: false,
      },
      plotOptions: {
        series: {
          states: {
            inactive: {
              opacity: 1,
            },
          },
        },
        spline: {
          marker: {
            enabled: false,
            states: {
              hover: {
                enabled: false,
              },
            },
          },
          dataGrouping: {
            enabled: false,
          },
        },
        column: {
          dataGrouping: {
            enabled: false,
          },
        },
      },
    };
  }, [
    batteryChargingPlan,
    height,
    currency,
    timezone,
    timezoneLabel,
    visibleSeries,
  ]);

  return (
    <div
      style={{
        height: "100%",
        borderRadius: 4,
        color: "white",
        padding: 10,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <H5>Charging Schedule</H5>
      {isFetchingBatteryChargingPlan ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            width: "100%",
          }}
        >
          <Spinner />
        </div>
      ) : hasData ? (
        <div>
          <HighchartsReact highcharts={Highcharts} options={chartOptions} />
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            width: "100%",
          }}
        >
          No data available
        </div>
      )}
    </div>
  );
}
