import {
  Button,
  ButtonGroup,
  Checkbox,
  Divider,
  Menu,
  MenuItem,
  Popover,
  Position,
} from "@blueprintjs/core";
import React, { useState } from "react";
import { createUseStyles } from "react-jss";

export type Ec1MultiSelectItem = {
  id: string;
  label: string;
  component?: React.ReactNode;
};

interface Ec1MultiSelectProps {
  width?: number;
  items: Ec1MultiSelectItem[];
  selectedItems: Ec1MultiSelectItem[];
  title: string;
  titlePlural?: string; // Defaults to adding "s" to title
  disabled?: boolean;
  onSelect: (selectedItems: Ec1MultiSelectItem[]) => void;
}

const useStyles = createUseStyles({
  button: {
    outline: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  label: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    fontFamily: "Barlow",
    paddingLeft: 2,
  },
  labelNoSelect: {
    extend: "label",
    opacity: 0.5,
  },
  menu: {
    minWidth: 150, // Override Blueprint's default strict 180
  },
  menuItem: {
    fontFamily: "Barlow",
    cursor: "pointer",
    outline: "none",
    padding: "6px 5px",
    "&:hover .bp5-control-indicator": {
      backgroundColor: "rgba(143, 153, 168, 0.15)",
    },
    "&.bp5-dark:hover .bp5-control-indicator": {
      backgroundColor: "rgba(143, 153, 168, 0.15)",
    },
    "&:hover input:checked ~ .bp5-control-indicator": {
      backgroundColor: "#215db0",
      boxShadow: "inset 0 0 0 1px rgba(255, 255, 255, 0.1)",
    },
    "&.bp5-dark:hover input:checked ~ .bp5-control-indicator": {
      backgroundColor: "#215db0",
      boxShadow: "inset 0 0 0 1px rgba(255, 255, 255, 0.1)",
    },
    "&:hover input:indeterminate ~ .bp5-control-indicator": {
      backgroundColor: "#215db0",
      boxShadow: "inset 0 0 0 1px rgba(255, 255, 255, 0.1)",
    },
    "&.bp5-dark:hover input:indeterminate ~ .bp5-control-indicator": {
      backgroundColor: "#215db0",
      boxShadow: "inset 0 0 0 1px rgba(255, 255, 255, 0.1)",
    },
  },
  menuItemText: {
    display: "flex",
    alignItems: "center",
  },
  checkboxNonInteractive: {
    margin: 0,
    pointerEvents: "none", // Prevent the Checkbox from handling pointer events
  },
});

const Ec1MultiSelect: React.FC<Ec1MultiSelectProps> = ({
  width = 175,
  items,
  selectedItems,
  title,
  titlePlural,
  disabled = false,
  onSelect,
}) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);

  const handleItemToggle = (item: Ec1MultiSelectItem) => {
    const isSelected = selectedItems.some(
      (selectedItem) => selectedItem.id === item.id
    );
    let newSelectedItems: Ec1MultiSelectItem[];
    if (isSelected) {
      newSelectedItems = selectedItems.filter(
        (selectedItem) => selectedItem.id !== item.id
      );
    } else {
      newSelectedItems = [...selectedItems, item];
    }
    onSelect(newSelectedItems);
  };

  const isItemSelected = (item: Ec1MultiSelectItem) =>
    selectedItems.some((selectedItem) => selectedItem.id === item.id);

  // Display label based on selection
  const getDisplayLabel = () => {
    if (selectedItems.length === 0) {
      // No selection
      return title;
    } else if (selectedItems.length === 1) {
      // Single selection
      return selectedItems[0].label;
    } else {
      // Multiple selections
      const pluralizedTitle = titlePlural || `${title}s`;
      return `${selectedItems.length} ${pluralizedTitle}`;
    }
  };

  const renderMenu = () => (
    <Menu className={classes.menu}>
      <MenuItem
        key="select-all"
        className={classes.menuItem}
        text={
          <div className={classes.menuItemText}>
            <Checkbox
              checked={selectedItems.length === items.length}
              indeterminate={
                selectedItems.length > 0 && selectedItems.length < items.length
              }
              onChange={() => {
                if (selectedItems.length === items.length) {
                  onSelect([]);
                } else {
                  onSelect([...items]);
                }
              }}
              className={classes.checkboxNonInteractive}
            />
            <span>
              {selectedItems.length === items.length
                ? "Deselect All"
                : "Select All"}
            </span>
          </div>
        }
        shouldDismissPopover={false}
        onClick={() => {
          if (selectedItems.length === items.length) {
            onSelect([]);
          } else {
            onSelect([...items]);
          }
        }}
      />
      <Divider />
      {items.map((item) => (
        <MenuItem
          key={item.id}
          className={classes.menuItem}
          text={
            <div className={classes.menuItemText}>
              <Checkbox
                checked={isItemSelected(item)}
                onChange={() => handleItemToggle(item)}
                className={classes.checkboxNonInteractive}
              />
              {item.component ? (
                <span>{item.component}</span>
              ) : (
                <span>{item.label}</span>
              )}
            </div>
          }
          shouldDismissPopover={false}
          onClick={() => handleItemToggle(item)}
        />
      ))}
    </Menu>
  );

  return (
    <ButtonGroup>
      <Popover
        content={renderMenu()}
        position={Position.BOTTOM}
        isOpen={isOpen}
        onInteraction={(nextOpen) => setIsOpen(nextOpen)}
        matchTargetWidth
        disabled={disabled}
        autoFocus={false}
      >
        <Button
          icon="filter"
          rightIcon="caret-down"
          alignText="left"
          className={classes.button}
          textClassName={
            selectedItems.length === 0 ? classes.labelNoSelect : classes.label
          }
          text={getDisplayLabel()}
          onClick={() => setIsOpen(!isOpen)}
          disabled={disabled}
          style={{ width }}
        />
      </Popover>
      <Button
        icon="cross"
        disabled={disabled || selectedItems.length === 0}
        onClick={(e) => {
          onSelect([]);
        }}
      />
    </ButtonGroup>
  );
};

export default Ec1MultiSelect;
