import { Spinner } from "@blueprintjs/core";
import { PropertyDailyMetrics } from "@ec1/types/PropertyDailyMetrics";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highstock";
import { DateTime } from "luxon";
import { useEffect, useMemo, useRef, useState } from "react";
import { CiExport, CiImport } from "react-icons/ci";
import { createUseStyles } from "react-jss";
import { useFetch } from "src/common/fetcher/effects";
import { ChartStateV2 } from "src/ui/utils/chartUtils";
import { getCurrencySymbol } from "../properties/tariffs";

interface PlatformBatteryCostChartProps {
  propertyId?: number;
  height?: number;
  chartState: ChartStateV2;
  timezone?: string;
}

const useStyles = createUseStyles({
  container: {
    height: (props: { height: number }) => props.height,
    borderRadius: 4,
    color: "white",
  },
  loadingContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: (props: { height: number }) => props.height - 30,
  },
  noDataContainer: {
    flex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 330,
    width: "100%",
  },
  hiddenContainer: {
    visibility: "hidden",
  },
  visibleContainer: {
    visibility: "visible",
  },
  chartContainer: {
    height: (props: { height: number }) => props.height,
  },
  costInfoContainer: {
    height: 50,
    display: "flex",
    flexDirection: "row",
    marginTop: 5,
    background: "transparent",
  },
  costInfoBox: {
    flex: 1,
    borderRadius: 4,
    display: "flex",
    flexDirection: "row",
    fontFamily: "Barlow",
    alignItems: "center",
    height: 40,
    fontSize: 18,
  },
  importCostBox: {
    extend: "costInfoBox",
    background: "#592e30",
    marginRight: 10,
  },
  exportCostBox: {
    extend: "costInfoBox",
    background: "#37513c",
  },
  iconContainer: {
    marginLeft: 5,
    marginRight: 5,
  },
  costLabel: {
    marginRight: 5,
  },
  currencySymbol: {
    marginRight: 2,
  },
  costValue: {
    marginRight: 10,
  },
  chart: {
    backgroundColor: "rgb(37, 42, 49)",
    borderRadius: 4,
  },
  loadingOverlay: {
    display: "flex",
    position: "relative",
    justifyContent: "center",
    height: (props: { height: number }) => props.height - 46,
    marginTop: (props: { height: number }) => 45 - props.height,
  },
});

export default function PlatformBatteryCostChart({
  propertyId,
  height = 320,
  chartState,
  timezone,
}: PlatformBatteryCostChartProps) {
  const classes = useStyles({ height });

  const chartRef = useRef<HighchartsReact.RefObject>(null);

  const [isLoading, setIsLoading] = useState(true);

  const dummyTs = 1704060000000; // For presentation purposes

  const fetchUrlMetrics = useMemo(() => {
    if (
      chartState.start &&
      chartState.end &&
      chartState.start <= DateTime.now().setZone(timezone || "system")
    ) {
      return `/api/property/daily-metrics/?property=${propertyId}&date__gte=${chartState.start.toISODate()}&date__lte=${chartState.end.toISODate()}&ordering=date`;
    }
    return null;
  }, [propertyId, chartState, timezone]);

  const fetchUrlForecasts = null;

  const { data: metricData, isLoading: isLoadingMetrics } = useFetch<{
    results: PropertyDailyMetrics[];
  }>(fetchUrlMetrics);

  const { data: forecastData, isLoading: isLoadingForecasts } = useFetch<{
    results: PropertyDailyMetrics[];
  }>(fetchUrlForecasts);

  const dataLoaded = useMemo(() => {
    return (
      (!fetchUrlMetrics || (fetchUrlMetrics && metricData)) &&
      (!fetchUrlForecasts || (fetchUrlForecasts && forecastData))
    );
  }, [fetchUrlForecasts, fetchUrlMetrics, forecastData, metricData]);

  const noDataAvailable = useMemo(() => {
    return fetchUrlMetrics === null && fetchUrlForecasts === null;
  }, [fetchUrlMetrics]);

  const {
    actualCost,
    estimatedCostNoBattery,
    estimatedCostNoAlgo,
    importCost,
    exportCost,
    currency,
  } = useMemo(() => {
    if (!metricData?.results) {
      return {
        actualCost: 0,
        estimatedCostNoBattery: 0,
        estimatedCostNoAlgo: 0,
        importCost: 0,
        exportCost: 0,
        currency: getCurrencySymbol("EUR"),
      };
    }

    const metrics = metricData.results.reduce(
      (acc, m) => ({
        actualCost:
          acc.actualCost +
          (typeof m.actual_cost === "number" ? m.actual_cost : 0),
        estimatedCostNoBattery:
          acc.estimatedCostNoBattery +
          (typeof m.cost_without_battery === "number"
            ? m.cost_without_battery
            : 0),
        estimatedCostNoAlgo:
          acc.estimatedCostNoAlgo +
          (typeof m.cost_without_algorithm === "number"
            ? m.cost_without_algorithm
            : 0),
        importCost:
          acc.importCost +
          (typeof m.imported_grid_energy_in_kwh === "number"
            ? m.imported_grid_energy_in_kwh
            : 0) *
            (typeof m.avg_price_per_kwh === "number" ? m.avg_price_per_kwh : 0),
        exportCost:
          acc.exportCost +
          (typeof m.exported_grid_energy_in_kwh === "number"
            ? m.exported_grid_energy_in_kwh
            : 0) *
            (typeof m.avg_export_price_per_kwh === "number"
              ? m.avg_export_price_per_kwh
              : 0),
      }),
      {
        actualCost: 0,
        estimatedCostNoBattery: 0,
        estimatedCostNoAlgo: 0,
        importCost: 0,
        exportCost: 0,
      }
    );

    return {
      actualCost: parseFloat(metrics.actualCost.toFixed(2)),
      estimatedCostNoBattery: parseFloat(
        metrics.estimatedCostNoBattery.toFixed(2)
      ),
      estimatedCostNoAlgo: parseFloat(metrics.estimatedCostNoAlgo.toFixed(2)),
      importCost: parseFloat(metrics.importCost.toFixed(2)),
      exportCost: parseFloat(metrics.exportCost.toFixed(2)),
      currency: getCurrencySymbol(
        metricData.results[0]?.tariff_currency ?? "EUR"
      ),
    };
  }, [metricData?.results]);

  useEffect(() => {
    if (noDataAvailable) {
      setIsLoading(false);
    } else if (dataLoaded && chartRef.current?.chart) {
      const chart = chartRef.current.chart;

      // Update the data
      chart.series[0].setData([[dummyTs, estimatedCostNoBattery]], false);
      chart.series[1].setData([[dummyTs, estimatedCostNoAlgo]], false);
      chart.series[2].setData([[dummyTs, actualCost]], false);

      // Redraw the chart
      chart.redraw();

      setIsLoading(false);
    }
  }, [
    actualCost,
    dataLoaded,
    estimatedCostNoAlgo,
    estimatedCostNoBattery,
    noDataAvailable,
  ]);

  const chartOptions = useMemo(() => {
    return {
      chart: {
        type: "column",
        height: height - 56,
        marginRight: 48,
        marginLeft: 72,
        marginTop: 32,
        borderRadius: 4,
        panning: {
          enabled: false,
        },
        zooming: {
          type: false,
        },
      },
      rangeSelector: {
        enabled: false,
      },
      xAxis: {
        visible: false,
        crosshair: false,
        tickInterval: 60 * 60 * 1000,
        min: dummyTs - 60 * 60 * 1000,
        max: dummyTs + 60 * 60 * 1000,
      },
      yAxis: {
        title: {
          text: null,
        },
        labels: {
          y: 4,
          style: {
            fontFamily: "Barlow",
            color: "white",
          },
          formatter: function (
            this: Highcharts.AxisLabelsFormatterContextObject
          ): string {
            const value =
              typeof this.value === "number"
                ? this.value.toFixed(2)
                : this.value;
            return (
              (typeof this.value === "number" && parseFloat(value) < 0
                ? "-"
                : "") +
              (currency ?? "") +
              value
            );
          },
        },
        plotLines: [{ value: 0, color: "#666", width: 1, zIndex: 1 }],
      },
      tooltip: {
        enabled: false,
      },
      series: [
        {
          name: "Cost without battery",
          type: "column",
          color: "#DB2C6F",
          showInLegend: true,
          borderWidth: 0,
        },
        {
          name: "Cost without algorithm",
          type: "column",
          color: "#BD6BBD",
          showInLegend: true,
          borderWidth: 0,
        },
        {
          name: "Actual Cost",
          type: "column",
          color: "#8EB125",
          showInLegend: true,
          borderWidth: 0,
        },
      ],
      legend: {
        enabled: true,
        itemStyle: {
          color: "white",
          fontFamily: "Barlow",
        },
        itemHoverStyle: {
          color: "#ccc",
          fontFamily: "Barlow",
        },
      },
      plotOptions: {
        column: {
          maxPointWidth: 40,
        },
        series: {
          pointPlacement: "on",
          dataLabels: {
            enabled: true,
            crop: false,
            overflow: "allow",
            style: {
              color: "white",
              fontFamily: "Barlow",
              textOutline: false,
              fontWeight: 500,
              letterSpacing: 0.5,
              align: "center",
            },
            formatter: function (this: Highcharts.PointLabelObject): string {
              const value =
                typeof this.y === "number" ? this.y.toFixed(2) : this.y;
              return (
                (typeof this.y === "number" && parseFloat(value as string) < 0
                  ? "-"
                  : "") +
                (currency ?? "") +
                value
              );
            },
          },
        },
      },
    };
  }, [currency, height]);

  return (
    <div className={classes.container}>
      <div>
        {isLoading && (
          <div className={classes.loadingContainer}>
            <Spinner />
          </div>
        )}
        {noDataAvailable ? (
          <div className={classes.noDataContainer}>No data available</div>
        ) : (
          <div
            className={
              isLoading ? classes.hiddenContainer : classes.visibleContainer
            }
          >
            <div className={classes.chartContainer}>
              <div className={classes.costInfoContainer}>
                <div className={classes.importCostBox}>
                  <div className={classes.iconContainer}>
                    <CiImport size={26} />
                  </div>
                  <div className={classes.costLabel}>{"Import Cost:"}</div>
                  <div className={classes.currencySymbol}>{currency}</div>
                  <div className={classes.costValue}>
                    {importCost.toFixed(2)}
                  </div>
                </div>
                <div className={classes.exportCostBox}>
                  <div className={classes.iconContainer}>
                    <CiExport size={26} />
                  </div>
                  <div className={classes.costLabel}>{"Export Revenue:"}</div>
                  <div className={classes.currencySymbol}>{currency}</div>
                  <div className={classes.costValue}>
                    {exportCost.toFixed(2)}
                  </div>
                </div>
              </div>
              <div className={classes.chart}>
                <HighchartsReact
                  highcharts={Highcharts}
                  constructorType={"stockChart"}
                  options={chartOptions}
                  ref={chartRef}
                />
                {(isLoadingMetrics || isLoadingForecasts) && (
                  <div className={classes.loadingOverlay}>
                    <Spinner />
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
