import { BatteryChargingPlan } from "@ec1/types/BatteryChargingPlan";
import { BatteryMetrics } from "@ec1/types/BatteryMetrics";
import { PropertyTariffMapping } from "@ec1/types/PropertyTariffMapping";
import { Tariff } from "@ec1/types/Tariff";
import { DateTime } from "luxon";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useFetch } from "src/common/fetcher/effects";
import { useAuthHeaders } from "../common/authentication/authentication";
import { usePropertyTimezone } from "../properties/properties";
import { getCurrencySymbol, getCurrentTariffId } from "../properties/tariffs";

export const BATTERY_METRICS_URL = `/api/devices/15m/battery-metrics/`;
export const BATTERY_FORECASTS_URL = `/api/devices/15m/battery-forecasts/`;
export const BATTERY_CHARGING_PLAN_URL = `/api/devices/battery-charging-plan/`;

export const PROPERTIES_URL = `/api/properties/`;
export const PROPERTY_MAPPINGS_URL = `/api/tariffs/property-mapping/`;
export const TARIFFS_URL = `/api/tariffs/`;

export interface ChartBatteryChargingPlanPoint {
  timestamp: number;
  value: number | null;
}

export interface ChartBatteryChargingPlanSocPoint
  extends ChartBatteryChargingPlanPoint {
  state: "GRID CHARGING" | "SOLAR CHARGING" | "EXPORTING" | "DISCHARGING";
}

interface ChartBatteryChargingPlanPeriod {
  start: number;
  end: number;
}

interface ChartBatteryChargingPlan {
  socData: ChartBatteryChargingPlanSocPoint[];
  importPrices: ChartBatteryChargingPlanPoint[];
  exportPrices: ChartBatteryChargingPlanPoint[];
  solarPeriod: ChartBatteryChargingPlanPeriod | null;
  chargeSlots: ChartBatteryChargingPlanPeriod[];
  exportSlots: ChartBatteryChargingPlanPeriod[];
  idleSlots: ChartBatteryChargingPlanPeriod[];
  lastSocTimestamp: number;
}

export default function useBatteryChargingPlan(
  batteryId: number,
  propertyId?: number
) {
  const headers = useAuthHeaders();

  const [isLoading, setIsLoading] = useState(false);

  const { timezone, timezoneLabel, isTimezoneLoaded } =
    usePropertyTimezone(propertyId);

  const tariffMappingFetchUrl = useMemo(() => {
    if (propertyId) {
      return `${PROPERTY_MAPPINGS_URL}?property=${propertyId}`;
    }
    return null;
  }, [propertyId]);

  const { data: propertyTariffMappingResponse } = useFetch<{
    results: PropertyTariffMapping[];
  }>(tariffMappingFetchUrl, { useCache: true });

  const tariffId = useMemo(() => {
    return getCurrentTariffId(propertyTariffMappingResponse?.results) ?? null;
  }, [propertyTariffMappingResponse?.results]);

  const tariffFetchUrl = useMemo(() => {
    if (tariffId) {
      return `${TARIFFS_URL}${tariffId}/`;
    }
    return null;
  }, [tariffId]);

  const { data: tariff } = useFetch<Tariff>(tariffFetchUrl, { useCache: true });

  const currency = useMemo(() => {
    if (propertyId && tariff) {
      return getCurrencySymbol(tariff.currency || "");
    }
    return null;
  }, [propertyId, tariff]);

  const [batteryChargingPlan, setBatteryChargingPlan] =
    useState<ChartBatteryChargingPlan | null>(null);
  const [isFetchingBatteryChargingPlan, setIsFetchingBatteryChargingPlan] =
    useState<boolean>(true);

  const interpolatePrices = useCallback(
    (
      prices: ChartBatteryChargingPlanPoint[],
      endTime: number
    ): ChartBatteryChargingPlanPoint[] => {
      if (prices.length === 0) {
        return prices;
      }

      const interpolatedPrices: ChartBatteryChargingPlanPoint[] = [];
      for (let i = 0; i < prices.length; i++) {
        const currentPrice = prices[i];
        const nextPrice = prices[i + 1] || {
          timestamp: endTime,
          value: currentPrice.value,
        };

        interpolatedPrices.push(currentPrice);

        const currentTime = DateTime.fromMillis(currentPrice.timestamp, {
          zone: timezone,
        });
        const nextTime = DateTime.fromMillis(nextPrice.timestamp, {
          zone: timezone,
        });
        let interpolationTime = currentTime.plus({ minutes: 15 });

        while (interpolationTime < nextTime) {
          interpolatedPrices.push({
            timestamp: interpolationTime.toMillis(),
            value: currentPrice.value,
          });
          interpolationTime = interpolationTime.plus({ minutes: 15 });
        }
      }

      return interpolatedPrices;
    },
    [timezone]
  );

  const fetchBatteryChargingPlan = useCallback(async () => {
    setIsLoading(true);
    setIsFetchingBatteryChargingPlan(true);

    const result: ChartBatteryChargingPlan = {
      socData: [],
      importPrices: [],
      exportPrices: [],
      solarPeriod: null,
      chargeSlots: [],
      exportSlots: [],
      idleSlots: [],
      lastSocTimestamp: 0,
    };

    const now = DateTime.now().setZone(timezone);
    const start = now.startOf("day");
    const end = now.endOf("day");

    // Calculate the actual duration of the day in minutes to handle DST
    const dayDuration = end.diff(start, "minutes").minutes + 1; // +1 to include the last minute
    const totalSlots = Math.ceil(dayDuration / 15);

    const startTimeStr = start.toUTC().toISO({ includeOffset: false });
    const endTimeStr = end.toUTC().toISO({ includeOffset: false });

    const pastChargeSlots: string[] = [];
    const pastExportSlots: string[] = [];

    let slotLength = 30; /* Default slot length 30mins */
    let lastSocTimestamp = 0;
    let lastMetricTimestamp = 0;
    let chargingPlan: BatteryChargingPlan | null = null;
    let nextDateChargingPlan: BatteryChargingPlan | null = null;
    let importPricesFetch, exportPricesFetch;
    let metrics: BatteryMetrics[] = [],
      forecasts: BatteryMetrics[] = []; // Cast to metrics as we want only the solar power property from this

    try {
      const chargingPlanFetch = fetch(
        `${BATTERY_CHARGING_PLAN_URL}?battery=${batteryId}`,
        { headers }
      );

      const metricsFetch = fetch(
        `${BATTERY_METRICS_URL}?battery=${batteryId}&utc_time__gte=${startTimeStr}&utc_time__lte=${endTimeStr}&ordering=utc_time`,
        { headers }
      );

      const forecastsFetch = fetch(
        `${BATTERY_FORECASTS_URL}?battery=${batteryId}&utc_time__gte=${startTimeStr}&utc_time__lte=${endTimeStr}&ordering=utc_time`,
        { headers }
      );

      if (propertyId) {
        importPricesFetch = fetch(`${PROPERTIES_URL}${propertyId}/prices/`, {
          headers,
        });
        exportPricesFetch = fetch(
          `${PROPERTIES_URL}${propertyId}/export-prices/`,
          { headers }
        );
      } else {
        importPricesFetch = Promise.resolve({
          ok: true,
          json: () => Promise.resolve([]),
        });
        exportPricesFetch = Promise.resolve({
          ok: true,
          json: () => Promise.resolve([]),
        });
      }

      const [
        chargingPlanResponse,
        metricsResponse,
        forecastsResponse,
        importPricesResponse,
        exportPricesResponse,
      ] = await Promise.all([
        chargingPlanFetch,
        metricsFetch,
        forecastsFetch,
        importPricesFetch,
        exportPricesFetch,
      ]);

      if (chargingPlanResponse.ok) {
        const chargingPlanData = await chargingPlanResponse.json();
        if (
          chargingPlanData &&
          chargingPlanData.results &&
          chargingPlanData.results.length > 0
        ) {
          const currentDate = start.toISODate();
          const currentDatePlan = chargingPlanData.results.filter(
            (p: BatteryChargingPlan) => p.date === currentDate
          );

          const nextDate = start.plus({ days: 1 }).toISODate();
          const nextDatePlan = chargingPlanData.results.filter(
            (p: BatteryChargingPlan) => p.date === nextDate
          );

          if (currentDatePlan.length > 0) {
            chargingPlan = currentDatePlan[0];

            slotLength = chargingPlan?.slot_length || 30;

            if (chargingPlan?.prev_socs?.length) {
              lastSocTimestamp = start
                .plus({
                  minutes:
                    slotLength * (chargingPlan.prev_socs.length - 1 + 0.5), // +0.5 for the extra midpoint needed
                })
                .toMillis();
              result.lastSocTimestamp = lastSocTimestamp;
            }
          }

          if (nextDatePlan.length > 0) {
            nextDateChargingPlan = nextDatePlan[0];
          }
        }
      }

      if (metricsResponse.ok) {
        const metricsData = await metricsResponse.json();
        if (
          metricsData &&
          metricsData.results &&
          metricsData.results.length > 0
        ) {
          metrics = metricsData.results;

          // Add past charge slots and past export slots
          metrics.forEach((m: BatteryMetrics) => {
            if (m.battery_power_in_kw > 0.1 && m.grid_power_in_kw < -0.1) {
              const metricTime = DateTime.fromISO(m.utc_time, {
                zone: "utc",
              }).setZone(timezone);
              pastChargeSlots.push(metricTime.toFormat("HH:mm:00"));
            } else if (
              m.battery_power_in_kw < -0.1 &&
              m.grid_power_in_kw > 0.1
            ) {
              const metricTime = DateTime.fromISO(m.utc_time, {
                zone: "utc",
              }).setZone(timezone);
              pastExportSlots.push(metricTime.toFormat("HH:mm:00"));
            }
          });

          lastMetricTimestamp = DateTime.fromISO(
            metrics[metrics.length - 1].utc_time,
            {
              zone: "utc",
            }
          ).toMillis();
        }
      }

      if (forecastsResponse.ok) {
        const forecastsData = await forecastsResponse.json();
        if (
          forecastsData &&
          forecastsData.results &&
          forecastsData.results.length > 0
        ) {
          forecasts = forecastsData.results;
        }
      }

      const allMetrics = [
        ...metrics,
        ...forecasts.filter(
          (f) =>
            DateTime.fromISO(f.utc_time, {
              zone: "utc",
            }).toMillis() > lastMetricTimestamp
        ),
      ];

      // Calculate solar period from all metrics
      const solarTimes = allMetrics
        .filter((m: BatteryMetrics) => m.solar_power_in_kw > 0.1)
        .map((m: BatteryMetrics) =>
          DateTime.fromISO(m.utc_time, { zone: "utc" })
            .setZone(timezone)
            .toMillis()
        )
        .sort((a, b) => a - b);

      if (solarTimes.length > 1) {
        result.solarPeriod = {
          start: solarTimes[0],
          end: solarTimes[solarTimes.length - 1],
        } as ChartBatteryChargingPlanPeriod;
      }

      const smoothenedSocs = (socs: number[]) => {
        const result: number[] = [];
        for (let i = 0; i < socs.length; i++) {
          result.push(socs[i]);
          if (i < socs.length - 1) {
            const interpolationPoints = Math.floor(slotLength / 15) - 1;
            for (let j = 1; j <= interpolationPoints; j++) {
              const interpolatedValue =
                socs[i] +
                (j / (interpolationPoints + 1)) * (socs[i + 1] - socs[i]);
              result.push(interpolatedValue);
            }
          } else {
            // Add the same value
            result.push(socs[i]);
          }
        }
        return result;
      };

      const socData = [
        ...(chargingPlan?.prev_socs
          ? smoothenedSocs(chargingPlan.prev_socs)
          : []),
        ...(chargingPlan?.socs ? smoothenedSocs(chargingPlan.socs) : []),
      ];

      // Adjust the number of SOC data points based on actual day duration
      const expectedSlots = totalSlots;
      const adjustedSocData = socData.slice(0, expectedSlots);

      // Update last point if next day's data is available
      if (
        adjustedSocData.length > 0 &&
        adjustedSocData.length === expectedSlots &&
        nextDateChargingPlan?.socs?.length
      ) {
        adjustedSocData[adjustedSocData.length - 1] =
          (adjustedSocData[adjustedSocData.length - 2] +
            nextDateChargingPlan.socs[0]) /
          2;
      }

      const metricsMap = new Map(
        metrics.map((metric) => [
          DateTime.fromISO(metric.utc_time, { zone: "utc" })
            .setZone(timezone)
            .toMillis(),
          metric,
        ])
      );

      result.socData = adjustedSocData.map((soc, index) => {
        const timestamp = start.plus({ minutes: index * 15 }).toMillis();
        const metric = metricsMap.get(timestamp);
        return {
          timestamp,
          value: metric?.soc ?? soc,
          state: !metric
            ? "DISCHARGING"
            : metric.battery_power_in_kw > 0.1
            ? metric.grid_power_in_kw < -0.1
              ? "GRID CHARGING"
              : "SOLAR CHARGING"
            : metric.battery_power_in_kw < -0.1 && metric.grid_power_in_kw > 0.1
            ? "EXPORTING"
            : "DISCHARGING",
        };
      });

      if (propertyId && importPricesResponse.ok) {
        const data = await importPricesResponse.json();
        if (data && data.results && data.results.length > 0) {
          const rawImportPrices = data.results
            .map((p: any) => ({
              timestamp: DateTime.fromISO(p.utc_time, {
                zone: "utc",
              })
                .setZone(timezone)
                .toMillis(),
              value: p.price_per_kwh,
            }))
            .filter(
              (p: ChartBatteryChargingPlanPoint) =>
                p.timestamp >= start.toMillis() && p.timestamp <= end.toMillis()
            )
            .sort(
              (
                a: ChartBatteryChargingPlanPoint,
                b: ChartBatteryChargingPlanPoint
              ) => a.timestamp - b.timestamp
            );
          result.importPrices = interpolatePrices(
            rawImportPrices,
            end.toMillis()
          );
        }
      }

      if (propertyId && exportPricesResponse.ok) {
        const data = await exportPricesResponse.json();
        if (data && data.results && data.results.length > 0) {
          const rawExportPrices = data.results
            .map((p: any) => ({
              timestamp: DateTime.fromISO(p.utc_time, {
                zone: "utc",
              })
                .setZone(timezone)
                .toMillis(),
              value: p.price_per_kwh,
            }))
            .filter(
              (p: ChartBatteryChargingPlanPoint) =>
                p.timestamp >= start.toMillis() && p.timestamp <= end.toMillis()
            )
            .sort(
              (
                a: ChartBatteryChargingPlanPoint,
                b: ChartBatteryChargingPlanPoint
              ) => a.timestamp - b.timestamp
            );
          result.exportPrices = interpolatePrices(
            rawExportPrices,
            end.toMillis()
          );
        }
      }

      const processSlots = (slots: string[], interval: number) => {
        return slots.map((slotTime) => {
          const [hours, minutes] = slotTime.split(":").map(Number);
          const startTime = start.set({ hour: hours, minute: minutes });
          const endTime = startTime.plus({ minutes: interval });
          return {
            start: startTime.toMillis(),
            end: endTime.toMillis(),
          };
        });
      };

      // Uncomment and adjust if past charge/export slots need to be included
      /*
      // Add the past charge slots
      if (pastChargeSlots.length > 0) {
        result.chargeSlots = processSlots(pastChargeSlots, 15); // 15 because past metrics are in 15m granularity
      }

      // Add the past export slots
      if (pastExportSlots.length > 0) {
        result.exportSlots = processSlots(pastExportSlots, 15); // 15 because past metrics are in 15m granularity
      }
      */

      // Add the future charge slots
      if (chargingPlan?.charge_slots && chargingPlan.charge_slots.length > 0) {
        result.chargeSlots = [
          ...result.chargeSlots,
          ...processSlots(chargingPlan.charge_slots, slotLength).filter(
            (slot) => slot.start >= lastSocTimestamp
          ),
        ];
      }

      // Add the future export slots
      if (chargingPlan?.export_slots && chargingPlan.export_slots.length > 0) {
        result.exportSlots = [
          ...result.exportSlots,
          ...processSlots(chargingPlan.export_slots, slotLength).filter(
            (slot) => slot.start >= lastSocTimestamp
          ),
        ];
      }

      // Add the future idle slots
      if (chargingPlan?.idle_slots && chargingPlan.idle_slots.length > 0) {
        result.idleSlots = [
          ...result.idleSlots,
          ...processSlots(chargingPlan.idle_slots, slotLength).filter(
            (slot) => slot.start >= lastSocTimestamp
          ),
        ];
      }

      // Consolidate consecutive slots to aid the Highcharts visual
      const consolidateSlots = (
        slots: ChartBatteryChargingPlanPeriod[]
      ): ChartBatteryChargingPlanPeriod[] => {
        if (slots.length === 0) {
          return slots;
        }

        const sortedSlots = slots.sort((a, b) => a.start - b.start);
        const consolidatedSlots: ChartBatteryChargingPlanPeriod[] = [
          sortedSlots[0],
        ];

        for (let i = 1; i < sortedSlots.length; i++) {
          const currentSlot = sortedSlots[i];
          const lastConsolidatedSlot =
            consolidatedSlots[consolidatedSlots.length - 1];

          if (currentSlot.start <= lastConsolidatedSlot.end) {
            // Slots overlap or are consecutive, merge them
            lastConsolidatedSlot.end = Math.max(
              lastConsolidatedSlot.end,
              currentSlot.end
            );
          } else {
            // Slots are not consecutive, add as a new slot
            consolidatedSlots.push(currentSlot);
          }
        }

        return consolidatedSlots;
      };

      result.chargeSlots = consolidateSlots(result.chargeSlots);
      result.exportSlots = consolidateSlots(result.exportSlots);
      result.idleSlots = consolidateSlots(result.idleSlots);

      setBatteryChargingPlan(result);
    } catch (error) {
      if (error instanceof Error) {
        if (error.name !== "AbortError") {
          console.error("Fetch error:", error.message);
        } else {
          console.error("An unexpected error occurred", error);
        }
      }
    } finally {
      setIsFetchingBatteryChargingPlan(false);
      setIsLoading(false);
    }
  }, [batteryId, headers, propertyId, timezone, interpolatePrices]);

  useEffect(() => {
    if (isTimezoneLoaded && !isLoading) {
      fetchBatteryChargingPlan();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchBatteryChargingPlan, isTimezoneLoaded]); // Do not add isLoading

  return {
    batteryChargingPlan,
    isFetchingBatteryChargingPlan,
    timezone,
    timezoneLabel,
    currency,
  };
}
