import React, { useMemo } from "react";
import { BaseEdge, EdgeProps, getBezierPath } from "reactflow";

interface GridEdgeTypedProps extends Partial<EdgeProps> {
  data: {
    color1: string;
    color2: string;
    isChild: boolean;
    isDisabled: boolean;
    isZoomed: boolean;
  };
  sourceX: number;
  sourceY: number;
  targetX: number;
  targetY: number;
}

const GridEdgeTyped: React.FC<GridEdgeTypedProps> = React.memo(
  ({
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    data,
    markerEnd,
  }) => {
    const edgePath = useMemo(() => {
      const [path] = getBezierPath({
        sourceX,
        sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition,
      });
      return path;
    }, [sourceX, sourceY, sourcePosition, targetX, targetY, targetPosition]);

    const animationStyle = useMemo(
      () => ({
        offsetPath: `path("${edgePath}")`,
        animation: "moveAlongPath 6s linear infinite, fadeIn 0.5s ease-out",
      }),
      [edgePath]
    );

    if (data.isChild && !data.isZoomed) {
      return null;
    }

    if (data.isDisabled) {
      return <BaseEdge path={edgePath} style={{ stroke: "#404854" }} />;
    }

    return (
      <>
        <BaseEdge
          path={edgePath}
          markerEnd={markerEnd}
          style={{
            stroke: data.color1,
            strokeWidth: data.isChild ? 1 : 2,
          }}
        />
        <circle
          style={{
            ...animationStyle,
            filter: `drop-shadow(3px 3px 5px ${data.color2})`,
          }}
          r={data.isChild ? 2 : 4}
          fill={data.color2}
        />
        <style>
          {`
            @keyframes moveAlongPath {
              0% {
                offset-distance: 0%;
              }
              100% {
                offset-distance: 100%;
              }
            }
            @keyframes fadeIn {
              0% {
                opacity: 0;
              }
              100% {
                opacity: 1;
              }
            }
          `}
        </style>
      </>
    );
  }
);

GridEdgeTyped.displayName = "GridEdgeTyped";

const GridEdge = GridEdgeTyped as React.FC<EdgeProps>;

export default GridEdge;
