import { Edge, Node } from "reactflow";
import "reactflow/dist/style.css";
import { Battery } from "../../../../__generated__/types/Battery";
import { PlatformCustomer } from "../../../../__generated__/types/PlatformCustomer";
import { PlatformProperty } from "../../../../__generated__/types/PlatformProperty";
import PlatformBatteryPopoverContent from ".././PlatformBatteryPopoverContent.react";
import {
  CHILD_SIZE,
  NODE_SIZE,
  SOURCE_BOTTOM_HANDLE_ID,
  SOURCE_RIGHT_HANDLE_ID,
  SOURCE_TOP_HANDLE_ID,
  TARGET_BOTTOM_HANDLE_ID,
  TARGET_LEFT_HANDLE_ID,
  TARGET_TOP_HANDLE_ID,
} from "./GridNode.react";
import PlatformPropertyPopoverContent from "./PlatformPropertyPopoverContent.react";

interface DeviceGraphResult {
  nodes: Node[];
  edges: Edge[];
}

export type GraphSorter = {
  id: string;
  field: string;
  name: string;
};

type GraphPoint = [number, number, number];

// Deterministic hash function to generate a seed from a string
function hashStringToNumber(str: string): number {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
    hash &= hash; // Convert to 32bit integer
  }
  return hash;
}

// Function to generate consistent noise based on node ID and position
function generateNoise(
  nodeId: string,
  isLeft: boolean,
  maxOffset: number = 30
): { x: number; y: number } {
  const hash = hashStringToNumber(nodeId);
  const seed = Math.abs(hash) % 1000; // Ensure positive seed
  const randomX = seed / 1000; // Normalize to [0, 1)

  // Generate x noise: positive for right, negative for left
  const noiseX = randomX * maxOffset;

  // Determine y noise direction based on hash (even or odd)
  const isYPositive = Math.floor(seed / 100) % 2 === 0;
  const noiseY = randomX * maxOffset * (isYPositive ? 1 : -1);

  return { x: isLeft ? -noiseX : noiseX, y: noiseY };
}

export function getTooltipContent(
  battery: Battery,
  type: "battery" | "home",
  properties?: PlatformProperty[],
  customers?: PlatformCustomer[]
) {
  if (type === "battery") {
    return <PlatformBatteryPopoverContent battery={battery} />;
  } else if (type === "home") {
    if (battery.assigned_property && properties) {
      const property = properties?.find(
        (p) => p.id === battery.assigned_property
      );
      if (property && customers) {
        const customer = customers?.find((c) => c.id === property?.customer);
        return (
          <PlatformPropertyPopoverContent
            property={property}
            customer={customer}
          />
        );
      }
    }
  }
}

// Function to sort by angle, then by Euclidian distance
function sortPoints(points: GraphPoint[]): GraphPoint[] {
  return points.slice().sort((a, b) => {
    // Sort by angle first (descending)
    if (b[2] !== a[2]) {
      return b[2] - a[2];
    }

    // If angles are equal, sort by Euclidean distance (descending)
    const distanceA = a[0] * a[0] + a[1] * a[1];
    const distanceB = b[0] * b[0] + b[1] * b[1];

    return distanceB - distanceA; // Descending order of distance
  });
}

function computeLayout(
  nodeMap: Map<Node, Node[]>,
  isZoomed: boolean,
  centerX: number,
  centerY: number,
  sorter: GraphSorter | null
): Node[] {
  const result: Node[] = [];
  const CHILD_OFFSET = (NODE_SIZE - CHILD_SIZE) / 2; // Distance between parent and child nodes
  const RADIUS_INCREMENT = 420; // Spacing between layers

  // Convert nodeMap keys to an array for easy manipulation
  const parentNodes = Array.from(nodeMap.keys());

  const points: GraphPoint[] = [];
  const layerCounts: number[] = [];

  let layerIndex = 0;
  let parentCounter = parentNodes.length;

  // Layer assignment strategy:
  // Start with the innermost layer having the fewest nodes and increase capacity for outer layers
  while (parentCounter > 0) {
    const layerCapacity = 3 + layerIndex * 3;
    const actualCapacity =
      parentCounter - layerCapacity >= 0 ? layerCapacity : parentCounter;
    layerCounts.push(actualCapacity);
    parentCounter -= actualCapacity;
    layerIndex++;
  }

  // Distribute the coordinates in each layer
  layerCounts.forEach((layerCount, index) => {
    const layerRadius = RADIUS_INCREMENT * (index + 1) * 0.75; // Increase radius for each layer

    // Calculate the angle increment to evenly space nodes across the semi-circle
    const angleIncrement = Math.PI / (layerCount + 1);

    let i = 0;
    while (i < layerCount) {
      // Spread nodes from left (-90 degrees) to right (+90 degrees)
      const angle = Math.PI - (i + 1) * angleIncrement;

      // Calculate base x and y positions based on the angle and layer radius
      let x = centerX + layerRadius * Math.cos(angle);
      let y = centerY + layerRadius * Math.sin(angle);

      // Determine if the node is on the left or right side
      const isLeft = angle > Math.PI / 2 && angle < (3 * Math.PI) / 2;

      // Apply deterministic noise to the position
      const noise = generateNoise(i.toString(), isLeft, 30);
      x += noise.x;
      y += noise.y;

      points.push([x, y, angle]);

      i++;
    }
  });

  // Sort the points, if needed
  const sortedPoints = sorter ? sortPoints(points) : points;

  // Sort the nodes according to the sorter, if needed
  // Add sorting handling per field as desired
  if (sorter && sorter.field === "station_state") {
    parentNodes.sort((a, b) => {
      const stateA = a.data.stationState;
      const stateB = b.data.stationState;

      const order = ["OFFLINE", "FAULT", "ONLINE"];

      const indexA = order.indexOf(stateA);
      const indexB = order.indexOf(stateB);

      return indexA - indexB;
    });
  }

  // Assign the coordinates to the nodes and calculate children positions
  parentNodes.forEach((node, index) => {
    const x = sortedPoints[index][0];
    const y = sortedPoints[index][1];

    if (isZoomed) {
      // Position child nodes relative to the parent node
      const children = nodeMap.get(node) || [];

      children.forEach((child, childIndex) => {
        switch (childIndex % 4) {
          case 0: // Left child
            child.position = { x: x, y: y + CHILD_OFFSET };
            break;
          case 1: // Top child
            child.position = { x: x + CHILD_OFFSET, y: y };
            break;
          case 2: // Right child
            child.position = { x: x + 2 * CHILD_OFFSET, y: y + CHILD_OFFSET };
            break;
          case 3: // Bottom child
            child.position = { x: x + CHILD_OFFSET, y: y + 2 * CHILD_OFFSET };
            break;
        }
        result.push(child);
      });
    } else {
      // Position parent node
      node.position = { x, y };
      result.push(node);
    }
  });

  return result;
}

export function getGridGraph(
  batteries: Battery[],
  properties: PlatformProperty[],
  customers: PlatformCustomer[],
  isZoomed: boolean,
  sorter: GraphSorter | null
): DeviceGraphResult {
  const nodes: Node[] = [];
  const edges: Edge[] = [];

  const nodeMap = new Map<Node, Node[]>();

  if (batteries.length === 0 || customers === null || properties === null) {
    return { nodes, edges };
  }

  const totalGridPowerImport = batteries
    .map((b) => (b.grid_power && b.grid_power < 0 ? Math.abs(b.grid_power) : 0))
    .reduce((accumulator, current) => accumulator + current, 0);

  const totalGridPowerExport = batteries
    .map((b) => (b.grid_power && b.grid_power > 0 ? b.grid_power : 0))
    .reduce((accumulator, current) => accumulator + current, 0);

  const centerX = 0;
  const centerY = 0;

  const root = {
    id: `big-grid`,
    type: "gridNode",
    position: { x: centerX, y: centerY - 500 },
    data: {
      subLabel: "Grid",
      icon: "grid",
      color: "#2196f3",
      isRoot: true,
      importValue: totalGridPowerImport,
      exportValue: totalGridPowerExport,
      isZoomed,
    },
  };

  nodes.push(root);

  batteries.forEach((battery) => {
    if (battery.id !== undefined) {
      const gridPower = battery?.grid_power ?? 0;
      const batteryPower = battery?.battery_power ?? 0;
      const solarPower = battery?.solar_power ?? 0;
      const consumerPower = battery?.consumer_power ?? 0;
      const isStationOffline = battery.station_state === "OFFLINE";

      const position = { x: 0, y: 0 };

      const gridNode = {
        id: `grid-${battery.id}`,
        type: "gridNode",
        position,
        data: {
          tooltipContent: getTooltipContent(
            battery,
            isZoomed ? "home" : "battery",
            properties,
            customers
          ),
          label: `${gridPower} W`,
          subLabel: "Home",
          icon: "home",
          color: "#00A396",
          isGroup: true,
          isOffline: isStationOffline,
          isZoomed,
          stationState: battery.station_state,
        },
      };

      nodeMap.set(gridNode, []);

      if (!isZoomed) {
        nodes.push(gridNode);
      }

      const subGridNodeId = `grid-inner-${battery.id}`;

      // Sets the direction of the grid edge according to the gridPower value
      if (gridPower > 0) {
        edges.push({
          id: `e-grid-${battery.id}-big-grid`,
          source: isZoomed ? subGridNodeId : gridNode.id,
          target: `big-grid`,
          type: "gridEdge",
          data: {
            color1: "#0b541a",
            color2: "#0b541a",
            isZoomed,
          },
          sourceHandle: SOURCE_TOP_HANDLE_ID,
          targetHandle: TARGET_BOTTOM_HANDLE_ID,
        });
      } else {
        edges.push({
          id: `e-big-grid-grid-${battery.id}`,
          source: `big-grid`,
          target: isZoomed ? subGridNodeId : gridNode.id,
          type: "gridEdge",
          data: {
            color1: "#7a1016",
            color2: "#7a1016",
            isDisabled: gridPower === 0,
            isZoomed,
          },
          sourceHandle: SOURCE_BOTTOM_HANDLE_ID,
          targetHandle: TARGET_TOP_HANDLE_ID,
        });
      }

      if (!isZoomed) {
        return;
      }

      const smallGridNode = {
        id: subGridNodeId,
        type: "gridNode",
        position,
        data: {
          label: `${gridPower} W`,
          subLabel: "Grid",
          icon: "grid",
          color: "#2196f3",
          isChild: true,
          isOffline: isStationOffline,
          isZoomed,
        },
      };

      const smallBatteryNode = {
        id: `battery-${battery.id}`,
        type: "gridNode",
        position,
        data: {
          tooltipContent: getTooltipContent(battery, "battery"),
          label: `${battery.battery_power ?? 0} W`,
          subLabel: "Battery",
          icon: "battery",
          color: "#9c27b0",
          battery: battery,
          isChild: true,
          isOffline: isStationOffline,
          isZoomed,
        },
      };

      const smallSolarNode = {
        id: `solar-${battery.id}`,
        type: "gridNode",
        position,
        data: {
          label: `${battery.solar_power ?? 0} W`,
          subLabel: "Solar",
          icon: "solar",
          color: "#ffeb3b",
          isChild: true,
          isOffline: isStationOffline,
          isZoomed,
        },
      };

      const smallHomeNode = {
        id: `home-${battery.id}`,
        type: "gridNode",
        position,
        data: {
          tooltipContent: getTooltipContent(
            battery,
            "home",
            properties,
            customers
          ),
          label: `${battery.consumer_power} W`,
          subLabel: "Home",
          icon: "home",
          color: "#00A396",
          isChild: true,
          isOffline: isStationOffline,
          isZoomed,
        },
      };

      nodeMap
        .get(gridNode)
        ?.push(smallSolarNode, smallGridNode, smallHomeNode, smallBatteryNode);

      nodes.push(
        smallGridNode,
        smallBatteryNode,
        smallSolarNode,
        smallHomeNode
      );

      if (gridPower < 0 && batteryPower < 0) {
        edges.push({
          id: `e-grid-inner-${battery.id}-battery-${battery.id}`,
          source: `grid-inner-${battery.id}`,
          target: `battery-${battery.id}`,
          type: "gridEdge",
          data: {
            color1: "#2196f3",
            color2: "#2196f3",
            isChild: true,
            isZoomed,
          },
          sourceHandle: SOURCE_BOTTOM_HANDLE_ID,
          targetHandle: TARGET_TOP_HANDLE_ID,
        });
      } else if (gridPower > 0 && batteryPower > 0) {
        edges.push({
          id: `e-battery-${battery.id}-grid-inner-${battery.id}`,
          source: `battery-${battery.id}`,
          target: `grid-inner-${battery.id}`,
          type: "gridEdge",
          data: {
            color1: "#9c27b0",
            color2: "#9c27b0",
            isChild: true,
            isZoomed,
          },
          sourceHandle: SOURCE_TOP_HANDLE_ID,
          targetHandle: TARGET_BOTTOM_HANDLE_ID,
        });
      } else {
        edges.push({
          id: `e-battery-${battery.id}-grid-inner-${battery.id}`,
          source: `battery-${battery.id}`,
          target: `grid-inner-${battery.id}`,
          type: "gridEdge",
          data: {
            color1: "#9c27b0",
            color2: "#9c27b0",
            isChild: true,
            isDisabled: true,
            isZoomed,
          },
          sourceHandle: SOURCE_TOP_HANDLE_ID,
          targetHandle: TARGET_BOTTOM_HANDLE_ID,
        });
      }

      edges.push({
        id: `e-grid-inner-${battery.id}-home-${battery.id}`,
        source: `grid-inner-${battery.id}`,
        target: `home-${battery.id}`,
        type: "gridEdge",
        data: {
          color1: "#2196f3",
          color2: "#2196f3",
          isChild: true,
          isDisabled: consumerPower <= 0 || gridPower >= 0,
          isZoomed,
        },
        sourceHandle: SOURCE_BOTTOM_HANDLE_ID,
        targetHandle: TARGET_LEFT_HANDLE_ID,
      });

      edges.push({
        id: `e-solar-${battery.id}-home-${battery.id}`,
        source: `solar-${battery.id}`,
        target: `home-${battery.id}`,
        type: "gridEdge",
        data: {
          color1: "#ffeb3b",
          color2: "#ffeb3b",
          isChild: true,
          isDisabled: solarPower <= 0 || consumerPower <= 0,
          isZoomed,
        },
        sourceHandle: SOURCE_RIGHT_HANDLE_ID,
        targetHandle: TARGET_LEFT_HANDLE_ID,
      });

      edges.push({
        id: `e-solar-${battery.id}-battery-${battery.id}`,
        source: `solar-${battery.id}`,
        target: `battery-${battery.id}`,
        type: "gridEdge",
        data: {
          color1: "#ffeb3b",
          color2: "#ffeb3b",
          isChild: true,
          isDisabled: solarPower <= 0 || batteryPower >= 0,
          isZoomed,
        },
        sourceHandle: SOURCE_RIGHT_HANDLE_ID,
        targetHandle: TARGET_TOP_HANDLE_ID,
      });

      edges.push({
        id: `e-solar-${battery.id}-grid-inner-${battery.id}`,
        source: `solar-${battery.id}`,
        target: `grid-inner-${battery.id}`,
        type: "gridEdge",
        data: {
          color1: "#ffeb3b",
          color2: "#ffeb3b",
          isChild: true,
          isDisabled: solarPower <= 0 || gridPower <= 0,
          isZoomed,
        },
        sourceHandle: SOURCE_RIGHT_HANDLE_ID,
        targetHandle: TARGET_BOTTOM_HANDLE_ID,
      });

      edges.push({
        id: `e-battery-${battery.id}-home-${battery.id}`,
        source: `battery-${battery.id}`,
        target: `home-${battery.id}`,
        type: "gridEdge",
        data: {
          color1: "#9c27b0",
          color2: "#9c27b0",
          isChild: true,
          isDisabled: batteryPower <= 0 || consumerPower <= 0,
          isZoomed,
        },
        sourceHandle: SOURCE_TOP_HANDLE_ID,
        targetHandle: TARGET_LEFT_HANDLE_ID,
      });
    }
  });

  edges.sort((a, b) =>
    a.data.isChild === b.data.isChild
      ? a.data.isDisabled === b.data.isDisabled
        ? 0
        : !a.data.isDisabled
        ? 1
        : -1
      : a.data.isChild
      ? 1
      : -1
  );

  const layoutNodes = computeLayout(
    nodeMap,
    isZoomed,
    centerX,
    centerY,
    sorter
  );

  return { nodes: [root, ...layoutNodes], edges: edges };
}
